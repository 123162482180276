@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-login-black{
  background-color: #1e1e1e;
}

.bg-monstrade-blue {
  background-color: #0052FF;
}

.monstrade-blue-color {
  color: #0052FF;
}

.bg-monstrade-button-green {
  background-color: #84bd31;
}

.bg-monstrade-button-green:hover {
  background-color: #6a942a;
}

.bg-monstrade-button-red {
  background-color: #df2e2e;
}

.bg-monstrade-button-red:hover {
  background-color: #9c1e1e;
}

.banner-gradient {
  background-color: #020202;
  background-image: linear-gradient(#020202, #001F4F);
}

.silver-gradient{
  background-color: #a3a3a3;
  background-image: linear-gradient(#a3a3a3,#707070);
}

.gold-gradient{
  background-color: #978102;
  background-image: linear-gradient(#927207,#c5c21e,#e2e045);
}

.platinium-gradient{
  background-color: #333333;
  background-image: linear-gradient(#333333,#585858,#a8a8a8);
}

.fa-caret-down {
  margin: auto !important;
}

.fa-caret-up {
  margin: auto !important;
}

.triangle-bottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #0052FF transparent transparent transparent;
}

.deposit-triangle{

  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 18% 100%, 0 57%);
  
}

.container-max {
  width: 80vw;
  max-width: 1080px;
}

.container-full {
  width: 100vw;
  max-width: 1440px;
}

.navbar-container{
  width: 100%;
}

ul {
  list-style-position: inside;
}

.collapse {
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .container-max {
    width: 90vw;
    max-width: 1080px;
  }
}

@media only screen and (min-width: 769px) {
  .container-max {
    width: 80vw;
    max-width: 1080px;
  }
}

.signals-triangle{
  width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right:  solid transparent;
	border-bottom: 100px solid #F3F3F3;
  position: absolute;
  bottom: 0;
  right: 0;
}

.news-triangle{
  width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right:  solid transparent;
	border-top: 100px solid #F3F3F3;
  position: absolute;
  top: 0;
  right: 0;
}

.xmas-button{
  background: linear-gradient(180deg, #E7D000 0%, #FFF48D 46.88%, #C68E00 100%);
}

.countdown-blue{
  color: #0052FF;
  text-align: center;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  filter: drop-shadow(2px 2px 54px rgb(0, 59, 127)) blur(0.2px);
}

.countdown-blue-2{
  color: #0052FF;
  text-align: center;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  filter: drop-shadow(2px 2px 54px rgb(0, 59, 127)) blur(0.2px);
}

.countdown-year{
  color: #D32626;
  text-align: center;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
  font-size: 99px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  filter: drop-shadow(2px 2px 34px #FF4E4E);
}

.countdown-red{
  color: #D30000;
  text-align: center;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
  font-size: 110px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  filter: drop-shadow(2px 2px 54px rgb(0, 59, 127)) blur(0.2px);
}

@media (max-width: 1024px) {
  .countdown-red {
    font-size: 87px; 
  }
}

.countdown-red-2{
  color: #D30000;
  text-align: center;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  filter: drop-shadow(2px 2px 54px rgb(0, 59, 127)) blur(0.2px);
}

.countdown-red-2{
  color: #D30000;
  text-align: center;
  text-shadow:
   -1px -1px 0 #fff,  
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
     1px 1px 0 #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  filter: drop-shadow(2px 2px 54px rgb(0, 59, 127)) blur(0.2px);
}

.countdown-div{
  border-radius: 16px;
  border: 1px solid #F59B9B;
  background: radial-gradient(86.52% 86.52% at 50.3% 50%, #900000 0%, #520000 100%);
  box-shadow: 0px 0px 19.4px 1px #800;
}

.countdown-inner-div{
  border-radius: 11px;
  border: 2px solid #FF4D00;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.carousel .carousel-status {
  visibility: hidden;
}

.xmas-green-button{
  border-radius: 8px;
  background: #2CBB22;
  box-shadow: 0px 0px 15px 0px rgba(44, 187, 34, 0.40);
  padding: 8px;
  padding-right: 40px;
  padding-left: 40px;
  color: white;
  font-weight: 600;
}

.carousel .control-dots .dot.selected {
  width: 32px;
  height: 12px;
  background-color: black;
  border-radius: 30%;
}

.carousel .control-dots .dot{
  border-radius: 90%;
  background-color: rgb(179, 179, 179);
  width: 12px;
  height: 12px;
}

.carousel .control-dots {
  width: 10%;
}

@media only screen and (max-width: 500px) {
  .carousel .control-dots {
    width: 100%;
    bottom: 350px;
  }
}

.carousel .thumbs{
  text-align: center;
}
.carousel .thumb{
  border: none;
}

.verticaltext {
  transform: rotate(-90deg);
  transform-origin: right, top;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin:right, top;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin:right, top;
}

.outlined-text {
  color: #F8F8F8;
  text-shadow:
   -1px -1px 0 #c5c5c5,  
    1px -1px 0 #c5c5c5,
    -1px 1px 0 #c5c5c5,
     1px 1px 0 #c5c5c5;
}

.outer-div{
  margin-left: calc(720px - 50vw);
  width: 100vw;
  display: flex;
  background: #F4F4F4;
  justify-content: center;
  align-items: center;
}

.outer-div2{
  margin-left: calc(720px - 50vw);
  width: 100vw;
  display: flex;
  background: #0052FF;
  justify-content: center;
  align-items: center;
}

.inner-div{
  max-width: 1440px;
}

.react-multi-carousel-item{
  align-self: center;
}

/* .carousel .slider-wrapper{
  overflow:  visible !important;
}

.carousel.carousel-slider{
  overflow:  visible !important;
} */

.phoneMockup {
  background-image: url('./assets/images/phone_mockup.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  .phoneMockup {
    background-image: none;
  }
}